<script setup lang="ts">
import { ref, onMounted, Ref } from "vue";

// Props
const props = withDefaults(
  defineProps<{
    onModalHidden?: Function;
    id?: string;
    size?: "small" | "large" | "extra-large";
    removePadding?:boolean
    shouldAddBackdrop?: boolean
  }>(),
  {
    size: "large",
    removePadding: false,
    shouldAddBackdrop:true
  }
);

// Refs
const modalRef: Ref<any> = ref(null);

// Lifecycle Hooks
onMounted(() => {
  modalRef.value?.addEventListener("hide.bs.modal", props.onModalHidden);
});
</script>

<template>
  <slot name="launcher"></slot>
<!--    <Teleport to="#teleport">-->
      <div
        ref="modalRef"
        class="modal fade"
        :id="props.id"
        tabindex="-1"
        aria-labelledby="twoFactorAuthSmsTitle"
        style="display: none"
        aria-hidden="true"
        :data-bs-backdrop="shouldAddBackdrop ? 'static' : null"

      >
        <div
          :class="{
            'modal-lg': size === 'large',
            'modal-sm': size === 'small',
            'modal-xl': size === 'extra-large',
          }"
          class="modal-dialog modal-dialog-centered two-factor-auth-sms mx-auto"
        >
          <div class="modal-content">
            <div class="modal-header bg-transparent border-0 p-0 m-0">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div v-if="removePadding" class="text-center modal-body">
              <!-- start -->
              <slot name="content"></slot>
              <!-- end -->
              <slot name="close"></slot>
            </div>
            <div v-else class="text-center modal-body pb-5 px-sm-5 mx-50">
              <!-- start -->
              <slot name="content"></slot>
              <!-- end -->
              <slot name="close"></slot>
            </div>
          </div>
      </div>
      </div>
<!--    </Teleport>-->
</template>

<style scoped>
@import url("@/assets/css/bootstrap-extended.css");
@import url("@/assets/css/sweetalert2.min.css");
@import url("@/assets/css/ext-component-sweet-alerts.css");
</style>
